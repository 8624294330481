<template>
    <b-container>
        <b-row class="justify-content-center mt-4">
            <b-col md="12">
                <iq-card>
                    <slot v-if="changeProfile">
                        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                            <b-form class="pt-5" @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Photo" rules="image">
                                        <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="photo"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{ $t('user.photo')}}
                                        </template>
                                        <b-form-file
                                            id="photo"
                                            v-on:change="onFileChange"
                                            accept="image/*"
                                            v-model="user.photo"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-file>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <div class="row">
                                    <div class="col-sm-3"></div>
                                    <div class="col col-3  text-right">
                                        <b-button type="submit" variant="primary" class="mr-2">Submit</b-button>
                                    </div>
                                </div>
                                <br/>
                            </b-form>
                        </ValidationObserver>
                    </slot>
                    <itemView v-else :prop-data="itemsToView" :loading="!dataFetched">
                        <template v-slot:saved="">
                            <b-badge variant="success">Saved</b-badge>
                        </template>
                        <template v-for="(slot, index) in ['draft', 'ড্রাফট']" v-slot:[slot]="">
                            <b-row :key="index">
                                <b-col md="3">
                                    <b-badge variant="danger">Draft</b-badge>
                                </b-col>
                                <b-col md="9" style="right: -17rem;">
                                    <router-link
                                        class="btn btn-primary float-right"
                                        type="button" size="sm"
                                        to="/irrigation/my-profile-form">
                                        {{ $t('nav.user.profileEditTitle') }}
                                        <i class="far fa-edit"></i>
                                    </router-link>
                                </b-col>
                            </b-row>
                        </template>
                    </itemView>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { helpers } from '@/mixins/helper-functions'
import itemView from '@/components/structure/itemView'
import RestApi, { irriSchemeServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { farmerProfileDetails } from '@/modules/external-user-service/irrigation/api/routes'
import Cuustom from '../../../../../store/custom'

export default {
    name: 'Show',
    components: { itemView },
    created () {
        this.loadData().then(() => {
            const tmp = this.getDetails()
            Object.freeze(tmp)
            this.farmerBasicInfo = this.formatList(tmp)
            this.user.id = this.formatList(tmp).farmer_id
        })
    },
    data () {
        return {
            user: {
                id: '',
                photo: []
            },
            photo: [],
            changeProfile: false,
            upload_photo: [],
            other: [],
            farmerBasicInfo: {},
            dataFetched: false,
            noPhoto: true,
            noNIDPhoto: true
        }
    },
    computed: {
        isBn () {
            return this.$i18n.locale === 'bn'
        },
        itemsToView () {
            const personalFormatted = [
                {
                    title1: this.$t('globalTrans.profileImage'),
                    value1: `<div class="add-img-user profile-img-edit">
                                <img style="${this.noPhoto ? 'max-width: 150px;height: 150px' : 'max-width: 200px;height: 150px'}" fluid src="${this.getProfileImage()}" alt="profile-pic" />
                            </div>`,
                    config: {
                        value1: {
                            type: 'html'
                        }
                    },
                    colSpan: {
                        title1: 1,
                        value1: 3
                    }
                },
                {
                    title2: this.$t('globalTrans.nid_photo'),
                    value2: `<div class="add-img-user profile-img-edit">
                                <img style="${this.noNIDPhoto ? 'max-width: 150px;height: 150px' : 'max-width: 300px;height: 150px'}" fluid src="${this.getNIDImage()}" alt="NID-Photo" />
                            </div>`,
                    config: {
                        value2: {
                            type: 'html'
                        }
                    },
                    colSpan: {
                        title2: 1,
                        value2: 3
                    }
                },
                {
                    title1: this.$t('globalTrans.name'),
                    value1: this.isBn ? this.farmerBasicInfo.name_bn : this.farmerBasicInfo.name,
                    colSpan: {
                        value1: 3
                    }
                },
                {
                    title1: this.$t('externalUserIrrigation.gender'),
                    value1: this.getGender(this.farmerBasicInfo.gender),
                    title2: this.$t('globalUserData.mobile_no'),
                    value2: this.farmerBasicInfo.mobile_no
                },
                {
                    title1: this.$t('globalUserData.email'),
                    value1: this.farmerBasicInfo.email,
                    title2: this.$t('cardPayment.nid'),
                    value2: this.farmerBasicInfo.nid
                },
                {
                    title1: this.$t('cardPayment.father_name'),
                    value1: this.isBn ? this.farmerBasicInfo.father_name_bn : this.farmerBasicInfo.father_name,
                    title2: this.$t('cardPayment.mother_name'),
                    value2: this.isBn ? this.farmerBasicInfo.mother_name_bn : this.farmerBasicInfo.mother_name
                }
            ]
            const addressFormatted = [
                {
                    title1: this.$t('farmerOperator.village'),
                    value1: this.isBn ? this.farmerBasicInfo.far_village_bn : this.farmerBasicInfo.far_village,
                    colSpan: {
                        value1: 3
                    }
                },
                {
                    title1: this.$t('complain.division'),
                    value1: this.isBn ? this.farmerBasicInfo.division_name_bn
                        : this.farmerBasicInfo.division_name,
                    title2: this.$t('complain.district'),
                    value2: this.isBn ? this.farmerBasicInfo.district_name_bn
                        : this.farmerBasicInfo.district_name
                },
                {
                    title1: this.$t('complain.upazila'),
                    value1: this.isBn ? this.farmerBasicInfo.upazilla_name_bn
                        : this.farmerBasicInfo.upazilla_name,
                    title2: this.$t('complain.union'),
                    value2: this.farmerBasicInfo.union_id ? (this.isBn ? this.farmerBasicInfo.union_name_bn
                        : this.farmerBasicInfo.union_name) : ' '
                }
            ]
            const applicationFormatted = [
                {
                    title2: this.$t('globalTrans.status'),
                    value2: this.isBn ? this.farmerBasicInfo.status_bn
                        : this.farmerBasicInfo.status_en,
                    lg: '4,8'
                }
            ]
            return {
                title: '',
                data:
                [
                    {
                        title: this.$t('globalTrans.personal_information'),
                        type: 'table',
                        data: personalFormatted
                    },
                    {
                        title: this.$t('globalTrans.address'),
                        type: 'table',
                        data: addressFormatted
                    },
                    {
                        title: this.$t('globalTrans.application_info'),
                        type: 'list',
                        data: applicationFormatted
                    }
                ]
            }
        },
        commonProfile () {
            return this.$store.state.ExternalUserIrrigation.commonProfile
        }
    },
    methods: {
        getProfileImage () {
            const img = this.farmerBasicInfo.attachment
            if (typeof img === 'undefined') {
                return '/images/user_icon.png'
            } else {
                this.noPhoto = false
                return irriSchemeServiceBaseUrl + 'download-attachment?file=' + img
            }
        },
        getNIDImage () {
            const nidPhoto = this.farmerBasicInfo.nid_photo
            if (typeof nidPhoto === 'undefined') {
                return '/images/user_icon.png'
            } else {
                this.noNIDPhoto = false
                return irriSchemeServiceBaseUrl + 'download-attachment?file=' + nidPhoto
            }
        },
        async register () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadinState = { loading: false, listReload: false }

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            var formData = new FormData()
            Object.keys(this.user).map(key => {
                if (key === 'photo') {
                formData.append(key, this.upload_photo)
                } else {
                formData.append(key, this.user[key])
                }
            })
            result = await RestApi.postData(authServiceBaseUrl, 'user/farmer-update', formData, config)
            if (result.success && !this.user.id) {
                loadinState.listReload = true
            }

            if (result.success) {
                this.$toast.success({
                    title: 'Success',
                    message: 'Data saved successfully',
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-1')
            } else {
                this.$refs.form.setErrors(result.errors)
                this.$toast.error({
                    title: 'Error',
                    message: 'Operation failed! Please, try again.'
                })
            }
        },
        onFileChange (e) {
            this.upload_photo = e.target.files[0]
        },
        statusChange () {
            this.changeProfile = true
        },
        viewItems () {
            return this.itemsToView
        },
        getDetails () {
            return this.$store.state.ExternalUserIrrigation.farmerBasicInfo
        },
        getGender (genId) {
            const gender = Cuustom.genderListEx.find(item => item.value === genId)
            if (gender) {
                if (this.$i18n.locale === 'bn') {
                    return gender.text_bn
                } else {
                    return gender.text_en
                }
            }
        },
        async loadData () {
            this.$store.dispatch('ExternalUserIrrigation/getFarmerBasicInfoDetails', {})
            await RestApi.getData(irriSchemeServiceBaseUrl, farmerProfileDetails)
            .then((response) => {
                this.dataFetched = true
                var data = {}
                new Promise((resolve, reject) => {
                    try {
                        if (response.data.length) {
                            Object.assign(data, response.data[0])
                        } else {
                            Object.assign(data, this.getCommonProfile())
                        }
                        // const authFields = ['email', 'name', 'name_bn']
                        // authFields.forEach((el) => {
                        //     data[el] = this.$store.state.Auth.authUser[el]
                        // })
                        data.status = data.status ?? 1
                        data.farmer_id = data.farmer_id ?? this.$store.state.Auth.authUser.id

                        resolve(data)
                    } catch (e) {
                        reject(e)
                    }
                }).then((data) => {
                    this.$store.dispatch('ExternalUserIrrigation/getFarmerBasicInfoDetails', data)
                    if (data.length < 1 || data.status === 1) {
                        this.$router.push('/irrigation/my-profile-form')
                    }
                })
            })
            .then(() => {
                this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
            })
        },
        formatList (data) {
            const commonObj = this.$store.state.ExternalUserIrrigation.commonObj
            const divisionList = commonObj.divisionList
            const districtList = commonObj.districtList
            const upazilaList = commonObj.upazilaList
            const unionList = commonObj.unionList
            const statusList = this.$store.state.ExternalUserIrrigation.farmerBasicInfoStatus

            var division = divisionList.find(item => item.value === data.far_division_id)
            var district = districtList.find(item => item.value === data.far_district_id)
            var upazilla = upazilaList.find(item => item.value === data.far_upazilla_id)
            var union = unionList.find(item => item.value === data.far_union_id)
            var status = statusList.find(item => item.value === data.status)
            let divisionData = {}
            let districtData = {}
            let upazillaData = {}
            let unionData = {}
            let statusData = {}

            if (division) {
               divisionData = { division_name: division.text_en, division_name_bn: division.text_bn }
            }
            if (district) {
               districtData = { district_name: district.text_en, district_name_bn: district.text_bn }
            }
            if (upazilla) {
               upazillaData = { upazilla_name: upazilla.text_en, upazilla_name_bn: upazilla.text_bn }
            }
            if (union) {
               unionData = { union_name: union.text_en, union_name_bn: union.text_bn }
            }
            if (status) {
               statusData = { status_en: status.text, status_bn: status.text_bn }
            }
            const mergedObj = Object.assign({}, data, divisionData,
            districtData, upazillaData, unionData, statusData, { attachment: 'uploads/farmer-basic/original/' + data.attachment }, { nid_photo: 'uploads/farmer-basic-nid/original/' + data.nid_photo })
            const personalItems = [
                'attachment',
                'nid_photo',
                'name',
                'name_bn',
                'email',
                'mobile_no',
                'father_name', 'father_name_bn',
                'mother_name', 'mother_name_bn',
                'nid',
                'gender',
                'district_name', 'district_name_bn',
                'division_name', 'division_name_bn',
                'upazilla_name', 'upazilla_name_bn',
                'union_name', 'union_name_bn', 'ward_no',
                'far_village', 'far_village_bn',
                'status_en',
                'status_bn'
            ]
            return helpers.filterObjectByArrayItem(mergedObj, personalItems)
        },
        getCommonProfile () {
            return {
                name: this.commonProfile.name,
                name_bn: this.commonProfile.name_bn,
                mobile_no: this.commonProfile.mobile_no,
                email: this.commonProfile.email,
                father_name: this.commonProfile.father_name,
                father_name_bn: this.commonProfile.father_name_bn,
                mother_name: this.commonProfile.mother_name,
                mother_name_bn: this.commonProfile.mother_name_bn,
                // gender: this.commonProfile.gender,
                // far_division_id: this.commonProfile.division_id,
                // far_district_id: this.commonProfile.district_id,
                // far_upazilla_id: this.commonProfile.upazila_id,
                // far_union_id: this.commonProfile.union_id,
                nid: this.commonProfile.nid
            }
        }
    }
}
</script>
